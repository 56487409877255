html {
  font-family: "Hanken Grotesk", sans-serif;
  box-sizing: border-box;
  font-size: 14px;
  background-color: var(--pale000);
  font-weight: 400;

  --red1: ##d93f3f;
  --red2: #de5959;
  --blue1: #3b5bde;
  --blue2: #748ae8;

  --gray2: #9da1a7;
  --gray1: #3d4043;
  --gray2: #a3a6ac;
  --gray3: #dadce0;
  --gray0: #45494c;
  --gray00: #404346;
  --gray5: #c2c6ce;

  --pale000: #f1f6fe;
  --pale00: #151A23;
  --pale0: #293547;
  --pale1: #3E4F6A;
  --pale2: #52698D;
  --pale3: #6783B0;
  --pale4: #7B9ED4;
  --pale5: #90B8F7;
  --pale6: #A0C2F8;
  --pale7: #B0CCF9;
  --pale8: #C0D6FA;
  --pale9: #CFE1FC;
  --pale10: #DFEBFD;
  --pale11: #EFF5FE;
}

body {
  font-family: "Hanken Grotesk", sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  color: var(--pale0);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  color: #1923a9 !important;
  text-decoration: none;
}

.positive-change {
  color: var(--blue1);
}

.negative-change {
  color: var(--red1);
}

.topmost-popular-nav {
  text-align: left;
  margin: 0 auto;
  margin-top: 16px;
  display: block;
  border-bottom: solid 1px var(--pale8);
  padding-bottom: 14px;
}

.topmost-nav-link {
  border-left: solid 1px var(--pale9);
  padding: 5px 15px;
  text-decoration: none;
  color: var(--pale2);
  font-size: 1.05em;
  font-weight: 500;
}

.topmost-nav-link:hover {
  color: var(--blue1);
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: var(--pale000);
}

.nav-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: start;
  max-width: 1200px;
  margin: 0 auto;
  border-bottom: solid 1px var(--pale9);
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo-container {
  color: var(--pale4);
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 3em;
  letter-spacing: -2px;
  padding: 8px 0px 0px 0px;
  margin-top: -16px;
}

.page-heading-text {
  font-size: 2.8em;
  color: var(--pale2);
  font-weight: 600;
  letter-spacing: -1px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  border-bottom: solid 1px var(--pale8);
}

.page-heading-text-2 {
  font-size: 2.8em;
  color: var(--pale2);
  font-weight: 600;
  letter-spacing: -1px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  border-bottom: solid 1px var(--pale8);
}

.page-heading-text-3 {
  font-size: 2.8em;
  color: var(--pale2);
  font-weight: 400;
  letter-spacing: -1px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: block;
  border-bottom: solid 1px var(--pale8);
}

.search-container {
  margin-left: 20px;
}


.app-frame {
  max-width: 1200px;
  margin: 0 auto;
}

.page-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.flow-container {
  display: block;
  flex-wrap: wrap;
}

.app-container {
  z-index: 1;
  font-family: "Hanken Grotesk", sans-serif;
  font-family: sans-serif;
  text-align: center;
  color: #444444;
  background-color: #ffffff2d;

  padding: 30px;
  margin: auto;
  margin-top: 15px;
  border-radius: 10px;
  /*display: flex;
  flex-direction: column;
  -moz-box-shadow: 0 0 33px #ccccccad;
  -webkit-box-shadow: 0 0 33px #ccccccad;
  box-shadow: 0 0 33px #ccccccad;*/
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: "Hanken Grotesk", sans-serif;
  text-align: left;
  font-weight: 400;
  letter-spacing: 0.07em;
}

h2 {
  font-weight: 600;
  font-size: 1.8em;
  letter-spacing: -1px;
  color: var(--pale2);
}

h3 {
  font-weight: 400;
  font-size: 2em;
}

h3.swatchHeader {
  margin-top: 20px;
  border-top: solid 2px #f0f0f0;
  padding-top: 20px;
}

.text1 {
  font-weight: 600;
  color: var(--pale1);
  font-size: 1.6em;
}

.text2 {
  font-weight: 600;
  color: var(--pale1);
  font-size: 1.1em;
}

.text3 {
  font-weight: 600;
  color: var(--pale2);
  font-size: 0.9em;
}

.text4 {
  font-weight: 500;
  color: var(--pale2);
  font-size: 1em;
}

.text5 {
  font-weight: 400;
  color: var(--pale2);
  font-size: 1em;
}

.text6 {
  font-weight: 500;
  color: var(--pale3);
  font-size: 0.9em;
}

.text7 {
  font-weight: 400;
  color: var(--pale3);
  font-size: 0.85em;
}

.text8 {
  font-weight: 400;
  color: var(--pale2);
  font-size: 0.75em;
}

.block {
  display: block !important;
}

.top-padding-1 {
  padding-top: 7px;
}

.top-padding-2 {
  padding-top: 12px;
}

.top-padding-3 {
  padding-top: 25px;
}

.bold {
  font-weight: 600 !important;
}

.lighter {
  opacity: 0.5;
}

.lighter2 {
  opacity: 0.2;
}

.up-arrow {
  max-width: 20px;
  margin-right: 10px;
  fill: var(--blue2) !important;
}

.down-arrow {
  max-width: 20px;
  margin-right: 10px;
  fill: var(--red1) !important;
}

.colorHistoryText {
  font-weight: 400;
  color: rgb(160, 160, 160);
  text-align: left;
  font-size: 1.2em;
  text-transform: uppercase;
}

.colorSampleCirlce {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: none;
  margin-right: 30px;
}

.colorSampleCirlce:hover {
  transition-duration: 500ms;
  transition-property: border-radius;
  border-radius: 10px;
}

.HexDisplay {
  color: red;
  border-bottom: solid 4px red;
}

/* BIG TEXT DISPLAY AT TOP */
.detailDisplay1 {
  color: rgb(62, 62, 62);
  font-size: 1.8em;
  border-bottom: solid 2px rgb(232, 232, 232);
  display: block;
  padding: 7px 0px 8px 0px;
  font-weight: 500;
  margin-top: -2px;
  width: 500px;
}

.displayColorContainer {
  display: flex;
  flex-direction: row !important;
  padding: 15px 0px 15px 0px;
}

.colorDetailsColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f9f9f9;
  align-items: stretch;
}

.footer-container {
  padding-top: 20px;
  margin: 0 auto;
  margin-top: 100px;
  font-size: 0.9em;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  opacity: 1;
  line-height: 1.2em;
  background-color: rgba(0, 0, 0, 0.034);
  color: var(--pale2);
  display: flex;
  flex-direction: row;
}

.footer-container>div {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.footer-link {
  border-bottom: dotted 1px var(--pale7);
  padding: 15px;
  text-decoration: none;
  color: var(--pale2);
  font-size: 1.05em;
  font-weight: 500;
}

.footer-link:hover {
  color: var(--pale00) !important;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background-color: var(--pale11);
}

.sliderControl {
  cursor: move;
  color: black;
  min-width: 70px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 70px;
  height: 40px;
  border-radius: 20px;
  background-color: #acacac;
  border: solid 2px none;
  margin: auto;
  user-select: none;
  margin-bottom: -20px;
  line-height: 3.45em;
  color: white;
  font-weight: 600;
  font-size: 0.8em;
}

.sliderControl:hover {
  background-color: #5d5d5d;
  /* min-width: 60px;
  height: 60px; */
  transition-duration: 200ms;
}

.sliderContainer {
  border-bottom: solid 3px #d1d1d1;
  margin-bottom: 20px;
}

.RGBSliderContainer {
  display: flex;
  flex-direction: column;
}

.copyClipboardIcon {
  margin-left: 10px;
  padding: 5px;
  margin-bottom: -3px;
  border-radius: 5px;
  opacity: 0.3;
  cursor: pointer;
}

.copyClipboardIcon:hover {
  background-color: rgb(212, 212, 212);
  opacity: 0.5;
}

.shadesContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.shadeCellContainer {
  display: flex;
  flex-direction: column;
  width: 10%;
  text-transform: uppercase;
  color: rgb(122, 122, 122);
  font-weight: 600;
  font-size: 0.8em;
  border: solid 3px transparent;
}

.shadeCell {
  width: 100%;
  height: 70px;
  margin-right: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  transition-duration: 500ms;
  transition-property: zoom;
}

.shadeCell:hover {
  transition-duration: 120ms;
  transition-property: margin-top;
  margin-top: -10px;
}

.shadeCellLabel {
  display: block;
  text-align: center;
  margin-top: 5px;
  color: #4c4c4c;
}

.shadeCellCopyButton {
  display: block;
  text-align: center;
  margin-top: 0px;
  padding: 6px;
  cursor: pointer;
  opacity: 0.4;
  font-size: 0.8em;
  border-radius: 8px;
  padding-bottom: 9px;
}

.shadeCellCopyButton:hover {
  background-color: #e8e8e8;
  color: #0d0d0d;
  font-weight: 500;
  opacity: 1;
}

.shadeCellCopyIcon {
  opacity: 0.3;
  margin-left: 5px;
  margin-bottom: -3px;
}

.currentColorMarker {
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 7px;
  margin-top: 28px;
}

.notification {
  font-size: 1.4em;
  font-weight: 300;
  text-align: left;
  padding: 20px;
  background-color: #e8f985;
  margin-bottom: 15px;
}

.makeMoreColorsButton {
  font-family: "Hanken Grotesk", sans-serif;
  border: solid 1px rgb(91, 91, 91);
  color: rgb(91, 91, 91);
  border: none;
  padding: 5px;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 15px;
  margin-top: -15px !important;
}

.makeMoreColorsButton:hover {
  color: white;
  background-color: #c53f92;
}

.fadeOut {
  font-size: 1.4em;
  font-weight: 300;
  text-align: left;
  padding: 20px;
  background-color: #e8f985;
  margin-bottom: 15px;
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
  font-size: 1.4em;
  font-weight: 300;
  text-align: left;
  padding: 20px;
  background-color: #e8f985;
  margin-bottom: 15px;
  opacity: 1;
  width: 100%;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.currency-box-1 {
  width: 29.5%;
  display: inline-block;
  margin-left: 1.5%;
  margin-bottom: 20px;
  border-radius: 8px;
  border: solid 1px var(--pale9);
  padding: 10px;
  font-size: 2.4em;
  text-align: left;
  line-height: 35px;
  height: 100px;
  max-height: 100px;
  background-color: rgba(255, 255, 255, 0.25);

}

.currency-box-inside {
  display: flex;
  flex-direction: column;
}

.currencySymbol1 {
  opacity: 0.5;
}

.footer-container {
  border-top: solid 1px var(--pale1);
  padding-top: 30px;
  padding-bottom: 200px;
  width: 1200px;
}

.button-link-1 {
  font-size: 1.8em;
  letter-spacing: -1px;
  padding: 10px;
  border-radius: 9px;
  border: solid 1px var(--pale8);
  display: inline-block;
  max-width: 130px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-decoration: none;
}

.button-link-2 {
  font-size: 1.1em;
  letter-spacing: -1px;
  padding: 8px;
  border-radius: 9px;
  border: none;
  color: var(--pale3);
  background-color: var(--pale9);
  display: inline-block;
  max-width: 130px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
}

.button-link-2:hover {
  color: var(--pale1);
  background-color: var(--pale10);
}

.tag1 {
  /* border: solid 2px var(--pale7); */
  color: var(--pale6);
  padding: 5px;
  border-radius: 10px;
  font-size: 0.4em;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -10px !important;
  height: 30px !important;
}

.search-result-display {
  display: flex;
  flex-direction: column !important;
  border-left: solid 3px var(--blue2);
  margin-top: 10px;
  padding-left: 15px;
}

.individual-search-result {
  border-bottom: solid 1px var(--pale9);
  margin-left: 0px;
  padding: 8px;
  font-weight: 700;
  display: block;
  color: var(--pale1);
  font-size: 1.1em;
  transition: 0.1s;
  text-decoration: none;
}

.individual-search-result:hover {
  border-bottom: solid 1px var(--blue2);
  padding-left: 12px;
  background-color: rgba(255, 255, 255, 0.794);
  cursor: pointer;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

/* FIRST BREAKPOINT */
@media all and (max-width: 600px) {

  .page-heading-text,
  .page-heading-text-2 {
    font-size: 2.2em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


/* SECOND BREAKPOINT */
@media all and (max-width: 900px) {
  body {
    padding-left: 15px;
  }

  .page-heading-text,
  .page-heading-text-2 {
    font-size: 2.2em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


/* THIRD BREAKPOINT */
@media all and (max-width: 1200px) {
  body {
    padding-left: 15px;
  }
}

/* FOURTH BREAKPOINT */
@media all and (max-width: 1600px) {}


/* FIFTH BREAKPOINT */
@media all and (min-width: 1601px) {}
